import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import PG_CWV_SECTION_ONE_CONTENT from "./index.content";
import "./index.scss";

const SectionOne = () => {
  const { downloadButton, link, linkList } = PG_CWV_SECTION_ONE_CONTENT;

  return (
    <section className="PgCWV-SectionOne">
      <div className="PgCWV-SectionOne__container">
        <h1 className="PgCWV-SectionOne__title">如何通过VPN使用AMZ侦探工具</h1>
        <p className="PgCWV-SectionOne-text">
          <b>1.</b>
          安装AMZ侦探工具是一个简单的过程.首先请您确认您的电脑已经安装了最新的谷歌Chrome浏览器。
        </p>
        <div className="PgCWV-SectionOne__grayLine" />
        <p className="PgCWV-SectionOne-text">
          <b>2.</b>
          由于谷歌在中国大陆受限，在中国大陆的用户要使用AMZ侦探工具，平时需要用科学上网工具。如果您已有科学上外网的工具，请确认您已经成功地科学上网后打开{" "}
          <TrackedLink
            category="CNWaVpnLanding"
            action={link.action}
            path={link.path}
          >
            {link.path}
          </TrackedLink>
          。如果没有可以尝试使用下列推荐的科学上网工具。
        </p>
        <ul className="PgCWV-SectionOne-list">
          {linkList.map((content, i) => (
            <li key={i}>
              <div className="PgCWV-SectionOne-list__marker" />
              <TrackedLink
                category="CNWaVpnLanding"
                action={content.action}
                target
                path={content.path}
              >
                {content.path}
              </TrackedLink>
            </li>
          ))}
        </ul>
        <div className="PgCWV-SectionOne__grayLine" />
        <p className="PgCWV-SectionOne-text PgCWV-SectionOne-text__lastText">
          <b>3.</b>
          完成科学上网工具下载安装后，并确认您已经成功地科学上网后，可使用谷歌浏览器点击
          <TrackedLink
            category="CNWaVpnLanding"
            action={link.action}
            path={link.path}
          >
            {link.path}
          </TrackedLink>
          使用AMZ侦探工具。
        </p>
        <TrackedLink
          category="CNWaVpnLanding"
          action={downloadButton.action}
          class="PgCWV-SectionOne__downloadButton"
          path={downloadButton.path}
        >
          点击打开AMZ侦探
        </TrackedLink>
      </div>
      <div className="PgCWV-SectionOne__yellowLine" />
    </section>
  );
};

export default SectionOne;
