const PG_CWV_SECTION_ONE_CONTENT = {
  linkList: [
    { path: "https://starvpnapp.com/", action: "starvpnapp" },
    { path: "https://www.betternet.co/", action: "betternet" },
    { path: "https://dukou.icu/", action: "dukou" },
    { path: "https://wannaflix.com/", action: "wannaflix" },
    { path: "https://www.hotspotshield.com/zh/", action: "hotspotshield" },
    { path: "https://bmkj88.com/auth/register", action: "bmkj88" },
    { path: "https://nord-help.net/zh/download/", action: "nord-help" },
  ],
  downloadButton: {
    path: "https://amzscout.net/app/",
    action: "点击打开AMZ侦探",
  },
  link: { path: "https://amzscout.net/app/", action: "app" },
}
export default PG_CWV_SECTION_ONE_CONTENT
