import React from "react";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import Layout from "../../layouts/LayoutCn";
import Seo from "../../components/Seo";
import SectionOne from "../../components/cn/wa-vpn/SectionOne";

const PgCWV = () => {

  return (
    <Layout bgHeader="#fff">
      <Seo
        title="如何通过VPN使用AMZ侦探工具"
        description="在中国如何通过VPN使用AMZ侦探工具"
        page="cn/wa-vpn"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <SectionOne />
    </Layout>
  );
};

export default PgCWV;
